label{
    color: white;
    margin-bottom: .5rem;
}
form{
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    margin: auto;
    max-width: 640px;
}
form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    background-color: rgba(255, 255, 255, .1);
    border-color:  rgba(255, 255, 255, .1);
    color:white

}
