.mask{
    height: 100vh;
    width: 100%;
    position: relative;
}
.intro-img{
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}
.mask::after{
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: .8;

}
.hero{
    width: 100%;
    height: 100%;
}
.hero .content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;

}
.hero .content h1{
    font-size: 3rem;
    padding: .8rem 0 1.5rem;
}
.hero .content p{
    font-size: 1.7 rem;
    font-weight: 400;
    text-transform: uppercase;
}
.content .btn{
    margin: 1rem .2rem;
}
@media screen and (max-width:640px){
    .hero .content h1{
        font-size: 2rem;
       
    }
    .hero .content p{
        font-size: 1.4 rem;
        
    }
    
}

