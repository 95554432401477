.hero-img {
    width: 100%;
    height: 100vh; /* Use viewport height to cover the entire screen */
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.hero-img::before {
    content: "";
    background: url("https://plus.unsplash.com/premium_photo-1678566153919-86c4ba4216f1?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1 {
    font-size: 2.4rem;
}

.hero-img p {
    font-size: 1.5rem;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}
