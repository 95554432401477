.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.project-container{
    max-width: 1040px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.project-card{
    background: black;
    padding: 1.4rem 1rem;

}
.project-card img{
    width: 100%;
}
.project-title{
    color: white;
    padding: 1rem 0;
}
.project-details p{
    
    padding: 0;
    margin: 0;
   

}

.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;
}
.pro-btns .btn{
    padding: .5rem 1rem;
}
@media screen and (max-width:740px) {
    .project-container{
        max-width: 90%;
        margin: auto;
        
        grid-template-columns:1fr;
        
    }
}

