.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1 rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;


}
.navbarSize{
    font-size: 1.5rem;
    

}
#nameSize{
    font-size: 1.5rem;
    margin-left: 30px;
}
.header-bg{
    background-color: rgb(0,0, .8);
    transition: .5s;

}
.nav-menu{
    display:flex;
    
}
.nav-menu li{

    padding:0 1rem;
}
.nav-menu li a{ 
    font-size: 1.2 rem;
    font-weight: 500;
}
.hamburger{
    display: none;
    margin-right: 20px;
}
@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height:100vh;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: .3s;

    }
    .nav-menu.active{
        left:0;
    }
    .nav-menu li{
        padding: 1rem 0;

    }
    .nav-bar li a {
        font-size: 3rem;
    }
    .hamburger{
        display: initial;
    }
    #nameSize{
        font-size: 1rem;
    }
}