@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}
body{
  background-color:black;
}
h1,h4,p,a{
  color:white;
  text-decoration: none;
}
ul{
  list-style-type: none;
}
.btn{
  padding: 12px 32px;
  font-size: 1 rem;
  color: black;
  background-color: rgb(243, 243, 24);
  border: 1 px solid white;
  font-weight: 600;
  cursor: pointer;
}
.btn-light{
  background: transparent;
  color: white;
 
}
.btn:hover{
  background-color: grey;
  color:white;
  transition: .3s;
}